import querystring from "qs"

// Function Send email
export async function sendEmail(
  mailFrom,
  mailReplyTo,
  mailTo,
  mailSubject,
  mailBody
) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/sendEmail"

  const params = {
    mailFrom,
    mailReplyTo,
    mailTo,
    mailSubject,
    mailBody
  }

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: querystring.stringify(params),
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("ERROR ==> ", error)
      })
      .then(response => {
        // console.log("SUCCESS ==> ", response)
        return response
      })
  ) 
}
// END : Function Send email

// Function Get Contentful entries
export async function getContentfulEntries(entriesContentType) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/getContentfulEntries"
  const contentfulSpaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID
  const contentfulManagementKey = process.env.GATSBY_CONTENTFUL_MANAGEMENT_KEY

  const params = {
    // Contentful
    contentfulSpaceId,
    contentfulManagementKey,
    // Entry
    entriesContentType,
  }

  return fetch(API_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: querystring.stringify(params),
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.error("ERROR ==> ", error)
    })
  // .then(response => {
  //   console.log("SUCCESS ==> ", response)
  //   return response
  // })
}
// END : Function Get Contentful entries

// Function Find Contentful entries
export async function findContentfulEntries(
  entriesContentType,
  queryFieldName,
  queryFieldValue
) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/findContentfulEntries"
  const contentfulSpaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID
  const contentfulManagementKey = process.env.GATSBY_CONTENTFUL_MANAGEMENT_KEY

  const params = {
    // Contentful
    contentfulSpaceId,
    contentfulManagementKey,
    // Entry
    entriesContentType,
    // Field
    queryFieldName,
    queryFieldValue,
  }

  return fetch(API_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: querystring.stringify(params),
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.error("ERROR ==> ", error)
    })
  // .then(response => {
  //   console.log("SUCCESS ==> ", response)
  //   return response
  // })
}
// END : Function Find Contentful entries

// Function Get Contentful entry
export async function getContentfulEntry(entryId) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/getContentfulEntry"
  const contentfulSpaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID
  const contentfulManagementKey = process.env.GATSBY_CONTENTFUL_MANAGEMENT_KEY

  const params = {
    // Contentful
    contentfulSpaceId,
    contentfulManagementKey,
    // Entry
    entryId,
  }

  return fetch(API_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: querystring.stringify(params),
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.error("ERROR ==> ", error)
    })
  // .then(response => {
  //   console.log("SUCCESS ==> ", response)
  //   return response
  // })
}
// END : Function Get Contentful entry

// Function Create Contentful entry
export async function createContentfulEntry(
  entryContentType,
  entryId,
  entryFieldsData,
  referenceId,
  referenceLinkField
) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/createContentfulEntry"
  const contentfulSpaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID
  const contentfulManagementKey = process.env.GATSBY_CONTENTFUL_MANAGEMENT_KEY

  const params = {
    // Contentful
    contentfulSpaceId,
    contentfulManagementKey,
    // Entry
    entryContentType,
    entryId,
    entryFieldsData,
    // Reference entry
    referenceId,
    referenceLinkField,
  }

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: querystring.stringify(params),
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("ERROR ==> ", error)
      })
      .then(response => {
        console.log("SUCCESS ==> ", response)
        return response
      })
  )
}
// END : Function Create Contentful entry

// Function Add Contentful entry child
export async function addContentfulEntryChild(entryId, childField, childId) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/addContentfulEntryChild"
  const contentfulSpaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID
  const contentfulManagementKey = process.env.GATSBY_CONTENTFUL_MANAGEMENT_KEY

  const params = {
    // Contentful
    contentfulSpaceId,
    contentfulManagementKey,
    // Entry
    entryId,
    // Child
    childField,
    childId,
  }

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: querystring.stringify(params),
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("ERROR ==> ", error)
      })
      .then(response => {
        console.log("SUCCESS ==> ", response)
        return response
      })
  )
}
// END : Function Add Contentful entry child

// Function Add Contentful entry children
export async function addContentfulEntryChildren(
  entryId,
  childrenField,
  childrenIds
) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/addContentfulEntryChildren"
  const contentfulSpaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID
  const contentfulManagementKey = process.env.GATSBY_CONTENTFUL_MANAGEMENT_KEY

  const params = {
    // Contentful
    contentfulSpaceId,
    contentfulManagementKey,
    // Entry
    entryId,
    // Children
    childrenField,
    childrenIds,
  }

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: querystring.stringify(params),
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("ERROR ==> ", error)
      })
      .then(response => {
        console.log("SUCCESS ==> ", response)
        return response
      })
  )
}
// END : Function Add Contentful entry children

// Function Create Gitlab file
export async function createGitlabFile() {
  const filePath = encodeURIComponent("src/data/test2.txt")

  const API_ENDPOINT =
    "https://gitlab.com/api/v4/projects/" +
    process.env.GATSBY_GITLAB_PROJECT_ID +
    "/repository/files/" +
    filePath

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "PRIVATE-TOKEN": process.env.GATSBY_GITLAB_ACCESS_KEY,
  }

  const params = {
    branch: "master",
    content: "createGitlabFile()",
    commit_message: "Test Gitlab API",
  }

  console.log("API_ENDPOINT", API_ENDPOINT)
  console.log("headers", headers)
  console.log("params", querystring.stringify(params))

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers,
      body: querystring.stringify(params),
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("ERROR ==> ", error)
      })
      .then(response => {
        console.log("SUCCESS ==> ", response)
        return response
      })
  )
}
// END : Function Create Gitlab file

// Function Create local JSON file
export async function createLocalFile(
  folderPath,
  fileName,
  fileContent,
  encoding
) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/localCreateFile"

  const params = {
    folderPath,
    fileName,
    fileContent,
    encoding,
  }

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: querystring.stringify(params),
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("ERROR ==> ", error)
      })
      .then(response => {
        console.log("SUCCESS ==> ", response)
        return response
      })
  )
}
// END : Function Create local JSON file

// Function Read local JSON folder content
export async function readLocalFolderFiles(folderPath, filesRegex, encoding) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/localReadFolderFiles"

  const params = {
    folderPath,
    filesRegex,
    encoding,
  }

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: querystring.stringify(params),
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("ERROR ==> ", error)
      })
      .then(response => {
        // console.log("SUCCESS ==> ", response)
        return response
      })
  )
}
// END : Function Read local JSON folder content

// Function Read local JSON file
export async function readLocalFile(folderPath, fileName, encoding) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/localReadFile"

  const params = {
    folderPath,
    fileName,
    encoding,
  }

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: querystring.stringify(params),
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("ERROR ==> ", error)
      })
      .then(response => {
        // console.log("SUCCESS ==> ", response)
        return response
      })
  )
}
// END : Function Read local JSON file

// Function Delete local file
export async function deleteLocalFile(folderPath, fileName) {
  const API_ENDPOINT =
    window.location.origin + "/.netlify/functions/localDeleteFile"

  const params = {
    folderPath,
    fileName,
  }

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: querystring.stringify(params),
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("ERROR ==> ", error)
      })
      .then(response => {
        // console.log("SUCCESS ==> ", response)
        return response
      })
  )
}
// END : Function Delete local file
