import React, { Component } from "react"

import metadata from "../data/metadata.json"

import { getContentfulEntries } from "../functions/apiLambda"
import { sortByKeyValue } from "../functions/sort"

const defaultContextValue = {
  data: {
    sections: [],
    reloadAllComments: true,
    metadata
  },
  set: () => {},
}

const Context = React.createContext(defaultContextValue)
const ContextConsumer = Context.Consumer

class ContextProvider extends Component {
  constructor() {
    super()

    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData,
    }
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))
  }

  componentDidMount() {
    this.loadAllComments()
  }

  componentDidUpdate() {
    // console.log("componentDidUpdate() ==> CONTEXT", this.state)
    if (this.state.data.reloadAllComments) {
      // console.log("reloadAllComments ==> OK")
      // if (!new RegExp("localhost").test(window.location.origin)) {
        this.loadAllComments()
      // }
      this.setState(state => ({
        data: {
          ...state.data,
          reloadAllComments: false,
        },
      }))
    }
  }

  loadAllComments() {
    // LOAD ALL COMMENTS
    getContentfulEntries("comment")
      // // Delete all comments email
      // .then(allComments => {
      //   allComments && allComments.map(comment => delete comment.email)
      //   // console.log("allComments ==> ", allComments)
      //   return allComments
      // })
      // Sort all comments
      .then(allComments => {
        allComments = sortByKeyValue(allComments, "ASC", "date")
        // console.log("allComments ==> ", allComments)
        return allComments
      })
      // Add sub comments mapping
      .then(allComments => {
        allComments.forEach(comment => {
          if (comment.comments) {
            let subComments = []
            comment.comments.forEach(subComment => {
              subComments = [...subComments, subComment.sys.id]
            })
            if (subComments.length === comment.comments.length) {
              comment.comments = Object.keys(allComments)
                .filter(key => subComments.includes(allComments[key].id))
                .reduce((obj, key) => {
                  obj[key] = allComments[key]
                  return obj
                }, [])
            }
          }
        })
        // console.log("allComments with sub comments ==> ", allComments)
        return allComments
      })
      .then(allComments => {
        // Add all comments to context state
        this.setState(state => ({
          data: {
            ...state.data,
            allComments,
          },
        }))
      })
    // END : LOAD ALL COMMENTS
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export { Context as default, ContextProvider, ContextConsumer }
