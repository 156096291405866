import React from "react"

export default props => (
  <div
    className="uk-flex uk-flex-center uk-animation-toggle"
    data-uk-parallax="opacity: 0,1; viewport: 1.3"
  >
    <a href={props.to} className="uk-link-heading uk-position-absolute">
      <span
        data-uk-icon="icon: arrow-up; ratio: 2"
        className="uk-animation-slide-bottom"
      />
    </a>
    <a href={props.to} className="uk-link-heading uk-position-absolute">
      <span
        data-uk-icon="icon: arrow-up; ratio: 2"
        className="uk-animation-slide-top uk-animation-reverse"
      />
    </a>
    &nbsp;
  </div>
)
