// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-event-js": () => import("./../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-part-js": () => import("./../src/templates/part.js" /* webpackChunkName: "component---src-templates-part-js" */),
  "component---src-templates-day-js": () => import("./../src/templates/day.js" /* webpackChunkName: "component---src-templates-day-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

