// Function Sort by value (up to 3 object levels)
export function sortByKeyValue(array, order, level1, level2, level3) {
  const sorted = [...array]

  sorted.sort((previous, current) => {
    // LEVEL 0
    if (!level1 && !level2 && !level3) {
      return previous < current ? -1 : previous > current ? 1 : 0
    }
    // LEVEL 1
    else if (level1 && !level2 && !level3) {
      return previous[level1] < current[level1]
        ? -1
        : previous[level1] > current[level1]
        ? 1
        : 0
    }
    // LEVEL 2
    else if (level1 && level2 && !level3) {
      return previous[level1][level2] < current[level1][level2]
        ? -1
        : previous[level1][level2] > current[level1][level2]
        ? 1
        : 0
    }
    // LEVEL 3
    else if (level1 && level2 && level3) {
      return previous[level1][level2][level3] < current[level1][level2][level3]
        ? -1
        : previous[level1][level2][level3] > current[level1][level2][level3]
        ? 1
        : 0
    } 
    // DEFAULT
    else {
      return 0
    }
  })

  // Ascending order by default
  if (new RegExp("DESC|desc").test(order)) {
    return sorted.reverse()
  } else {
    return sorted
  }
}
// END : Function Sort by value (up to 3 object levels)
