import React from "react"

export default ({ icon, text, count, url }) => (
  <a
    href={url}
    className="uk-flex uk-flex-middle uk-flex-center uk-button uk-button-default uk-padding-remove button-transparent-dark uk-margin-small-left uk-margin-small-right uk-position-relative"
  >
    {icon && (
      <span className="material-icons-outlined uk-flex-none uk-margin-small-left uk-visible@xl">
        {icon}
      </span>
    )}
    <span className="uk-text-truncate uk-text-bold uk-margin-small-left uk-margin-small-right">
      {text}
    </span>

    {count && (
      <span
        className="uk-badge uk-position-absolute uk-text-small"
        style={{
          top: "-10px",
          right: "-10px",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          color: "white",
        }}
      >
        {count}
      </span>
    )}
  </a>
)
