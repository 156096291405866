import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link, navigate } from "gatsby"

export default ({ path }) => (
  <StaticQuery
    query={graphql`
      query {
        blog: contentfulBlog {
          contentful_id
          name
          title
          events {
            contentful_id
            name
            title
            slug
            parts {
              contentful_id
              name
              title
              slug
              days {
                contentful_id
                name
                title
                slug
              }
            }
          }
        }
      }
    `}
    render={({ blog }) => (
      <ul
        className="uk-nav-default uk-nav-parent-icon uk-margin-left"
        data-uk-nav
      >
        <li className={`${path === "/" && "uk-active uk-open"}`}>
          <Link className="uk-text-truncate" to={"/"}>
            Accueil
          </Link>
        </li>
        {// EVENTS
        blog.events &&
          blog.events.map(event => {
            const eventUrl = "/" + event.slug
            return (
              <li
                key={event.contentful_id}
                className={`uk-parent ${new RegExp(eventUrl).test(path) &&
                  "uk-active uk-open"}`}
              >
                <Link
                  onClick={() => navigate(eventUrl)}
                  className="uk-text-truncate"
                  to={"/#"}
                >
                  {event.title}
                </Link>
                <ul
                  className="uk-nav-default uk-nav-parent-icon uk-margin-left"
                  data-uk-nav
                >
                  {// PARTS
                  event.parts &&
                    event.parts.map(part => {
                      const partUrl = eventUrl + "/" + part.slug
                      return (
                        <li
                          key={part.contentful_id}
                          className={`uk-parent ${new RegExp(partUrl).test(
                            path
                          ) && "uk-active uk-open"}`}
                        >
                          <Link
                            onClick={() => navigate(partUrl)}
                            className="uk-text-truncate"
                            to={"/#"}
                          >
                            {part.name + " : " + part.title}
                          </Link>
                          <ul
                            className="uk-nav-default uk-nav-parent-icon uk-margin-left"
                            data-uk-nav
                          >
                            {// DAYS
                            part.days &&
                              part.days.map(day => {
                                const dayUrl = partUrl + "/" + day.slug
                                return (
                                  <li
                                    key={day.contentful_id}
                                    className={`${new RegExp(dayUrl).test(
                                      path
                                    ) && "uk-active"}`}
                                  >
                                    <Link
                                      onClick={() => navigate(dayUrl)}
                                      className="uk-text-truncate"
                                      to={"/#"}
                                    >
                                      {day.name + " : " + day.title}
                                    </Link>
                                  </li>
                                )
                              })
                            // END DAYS
                            }
                          </ul>
                        </li>
                      )
                    })
                  // END PARTS
                  }
                </ul>
              </li>
            )
          })
        // END EVENTS
        }
      </ul>
    )}
  />
)
